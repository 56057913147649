angular.module("app.config", [])
.constant("envConfig", {"envName":"production","APIURL":"https://ice.synthego.com/api","hubspot":{"formIDs":{"downloadResult":"71b943a3-f0f6-4121-aa4e-c1f7812c0493"}},"store":{"APIURL":"https://api.synthego.com","APIKey":"WANYqXrAIukAqV6JP43PtgOqzTDtd5r3kphQl0PWImZR0g6Bqhf9RMWfKVRveEg1"}})
.constant("version", "3.0")
.constant("appPath", "./app")
.constant("assetsPath", "./app/assets")
.constant("commonPath", "./app/common")
.constant("buildPath", "./dist")
.constant("depPaths", ["./app/bower_components/angular/angular.js","./app/bower_components/angular-ui-router/release/angular-ui-router.min.js","./app/bower_components/angular-sanitize/angular-sanitize.min.js","./app/bower_components/angular-animate/angular-animate.min.js","./app/bower_components/angular-messages/angular-messages.min.js","./app/bower_components/angular-flash-alert/dist/angular-flash.min.js","./app/bower_components/angular-ui-select/dist/select.min.js","./app/bower_components/angular-ui-validate/dist/validate.min.js","./app/bower_components/angular-bootstrap/ui-bootstrap-tpls.min.js","./app/bower_components/ngstorage/ngStorage.min.js","./app/bower_components/d3/d3.min.js","./app/bower_components/c3/c3.min.js","./app/bower_components/file-saver/FileSaver.min.js","./app/bower_components/waypoints/lib/noframework.waypoints.min.js","./app/bower_components/waypoints/lib/shortcuts/inview.min.js","./app/bower_components/angular-mocks/angular-mocks.js","./app/bower_components/angulartics/dist/angulartics.min.js","./app/bower_components/angulartics-google-analytics/dist/angulartics-ga.min.js","./app/bower_components/angulartics-hubspot/dist/angulartics-hubspot.min.js","./app/bower_components/ng-file-upload/ng-file-upload.min.js","./app/bower_components/angular-timeago/dist/angular-timeago.min.js","./app/bower_components/sine-waves/sine-waves.min.js","./app/bower_components/angular-tablesort/js/angular-tablesort.js","./app/bower_components/ng-scrollable/min/ng-scrollable.min.js","./app/bower_components/clipboard/dist/clipboard.min.js","./app/bower_components/ngclipboard/dist/ngclipboard.min.js","./app/bower_components/jquery/dist/jquery.slim.min.js","./app/bower_components/angular-scroll/angular-scroll.min.js","./app/bower_components/angular-mousetrap-service/release/angular-mousetrap-service.min.js"])
.constant("rootUrls", {"labs":"labs.synthego.com","ice":"ice.synthego.com","design":"design.synthego.com"});

'use strict';

angular.module('app', [
    'ui.router',
    'ui.bootstrap',
    'ngAnimate',
    'ngSanitize',
    'ngMessages',
    'ngStorage',
    'ngFlash',
    'ui.select',
    'ui.validate',
    'angular-c3-simple',
    'angulartics',
    'angulartics.google.analytics',
    'angulartics.hubspot',
    'ngFileUpload',
    'yaru22.angular-timeago',
    'tableSort',
    'ngScrollable',
    'ngclipboard',
    'duScroll',
    'angular-mousetrap',
    'app.config'
  ])
  .config(['$stateProvider', '$urlRouterProvider', '$locationProvider', '$urlMatcherFactoryProvider', 'FlashProvider', function($stateProvider, $urlRouterProvider, $locationProvider, $urlMatcherFactoryProvider, FlashProvider) {
    // An array of state definitions
    var states = [
      {
        name: 'error',
        templateUrl: './views/error.1bc5504d.html',
        controller: 'error',
        params: {
          message: 'Something went wrong.',
          details: null,
          backTo: 'analyze'
        },
        data: {
          title: 'Error',
          pageClass: 'error'
        }
      },
      {
        name: 'analyze',
        url: '/',
        templateUrl: './views/analyze/analyze.5dc6dc5d.html',
        controller: 'analyzeForm',
        data: {
          title: 'CRISPR Performance Analysis',
          pageClass: 'analyze'
        }
      },
      {
        name: 'analyze.results',
        url: 'analyze/results/{run_id}',
        views: {
          '@': {
            templateUrl: './views/analyze/analyze-results.e5b54d27.html',
            controller: 'analyzeResults'
          },
          'results@analyze.results' : {
            templateUrl: './views/analyze/_analyze-summary.e5576e00.html'
          }
        },
        data: {
          title: 'CRISPR Analysis Results',
          pageClass: 'analyze-results results-view'
        }
      },
      {
        name: 'analyze.results.detail',
        url: '/{sample_label}',
        views: {
          'results@analyze.results' : {
            templateUrl: './views/analyze/_analyze-detail.e8648468.html',
            controller: 'analyzeResultsDetail'
          }
        },
        data: {
          title: 'CRISPR Analysis Detail',
          pageClass: 'analyze-results results-view'
        }
      }
    ]

    // Loop over the state definitions and register them
    states.forEach(function(state) {
      $stateProvider.state(state);
    });

    $urlRouterProvider.otherwise('/');
    $locationProvider.hashPrefix('');

    // Match trailing slashes in URLs
    $urlMatcherFactoryProvider.strictMode(false);

    // Enable this once index document is set on AWS
    //$locationProvider.html5Mode(true);

    FlashProvider.setTimeout(0);
  }])

  .run(['$rootScope', '$state', '$stateParams', '$transitions', '$uibModalStack', 'Flash', 'envConfig', 'version', 'rootUrls', function ($rootScope, $state, $stateParams, $transitions, $uibModalStack, Flash, envConfig, version, rootUrls) {
    // Make states available for classes and conditionals
    $rootScope.$state = $state;
    $rootScope.$stateParams = $stateParams;

    // Navbar initial state
    $rootScope.isNavCollapsed = true;
    $rootScope.isCollapsed = false;

    $rootScope.helpText = {
      sample : 'Green: no issue.  Yellow: ICE inferred an indel percentage, but the underlying data may have issues.  Red: unable to analyze the sample due to quality or other issues.',
      ice: 'Indel percentage',
      rsq : 'How well the proposed indel distribution fits the Sanger sequence data of the edited sample.',
      ko_score: 'Proportion of indels that indicate a frameshift or are 21+bp in length. Assumes all edits are in a coding region.',
      ko_score_summary: 'Proportion of indels that indicate a frameshift or are 21+bp in length. Assumes all edits are in a coding region. Not calculated for knockin experiments.',
      ki_score: 'Proportion of indels that indicate a knockin insert.',
      ki_score_summary: 'Proportion of indels that indicate a knockin insert. Requires uploading a donor sequence.',
      knockinIndicator: 'Enter a donor sequence with homology arms to detect knockin inserts predicted by homology directed repair.',
      guide_sequence: 'CRISPR/Cas9 target',
      pam_sequence: 'CRISPR/Cas9 recognition sequence',
      nuclease_type: 'The Nuclease used for this edit'
    }

    $rootScope.envConfig = envConfig;
    $rootScope.version = version;
    $rootScope.date = new Date();
    $rootScope.rootUrls = rootUrls;
    
    // Triggers on any transition
    $transitions.onStart({ to: '**' }, function(transition) {
      // Clear modals whenever state starts to change and cancel transition
      var top = $uibModalStack.getTop();
      if (top) {
        $uibModalStack.dismiss(top.key);
        $state.reload();
      }
      // Clear any flash messages
      Flash.clear();
    });
  }]);

// Modified to remove data watching

// using C3 (be sure to include it before, same with D3, which C3 requires)
;(function(c3) {
    'use strict';

    // module definition, this has to be included in your app
    angular.module('angular-c3-simple', [])

    // service definition, if you want to use itm you have to include it in controller
    // this service allows you to access every chart by it's ID and thanks to this,
    // you can perform any API call available in C3.js http://c3js.org/examples.html#api
    .service('c3SimpleService', [function() {
        return {};
    }])

    // directive definition, if you want to use itm you have to include it in controller
    .directive('c3Simple', ['c3SimpleService', function(c3SimpleService) {
        return {
          // this directive can be used as an Element or an Attribute
          restrict: 'EA',
          scope: {
            // setting config attribute to isolated scope
            // config object is 1:1 configuration C3.js object, for avaiable options see: http://c3js.org/examples.html
            config: '='
          },
          template: '<div></div>',
          replace: true,
          controller: ['$scope','$element', function($scope, $element) {
            // Wait until id is set before binding chart to this id
            $scope.$watch($element, function() {
              
              if ('' === $element[0].id) {
                return;
              }

              // binding chart to element with provided ID
              $scope.config.bindto = '#' + $element[0].id;

              //Generating the chart on every data change
              $scope.$watch('config', function(newConfig, oldConfig) {
                
                // adding (or overwriting) chart to service c3SimpleService
                // we are regenerating chart on each change - this might seem slow and unefficient
                // but works pretty well and allows us to have more controll
                c3SimpleService[$scope.config.bindto] = c3.generate(newConfig);
                
                // if there is no size specified, we are assuming, that chart will have width
                // of its container (proportional of course) - great for responsive design
                if (!newConfig.size) {
                  c3SimpleService[$scope.config.bindto].resize();
                }
                
                // This kills performance on large data sets - JM
                //
                // only updating data (enables i.e. animations)
                //$scope.$watch('config.data', function(newData, oldData) {
                //  if ($scope.config.bindto) {
                //    c3SimpleService[$scope.config.bindto].load(newData);
                //  }
                //}, true);
              }, true);
            });
          }]
        };
    }]);
}(c3));

angular.module('app')
  .directive('analyzeAlignmentChart', ['$q', '$http', '$timeout', function($q, $http, $timeout) {
    return {
      restrict: 'EA',
      scope: {
        filePath: '='
      },
      templateUrl: './views/analyze/_analyze-alignment-chart.803c68b1.html',
      link: function(scope, element, attrs) {

        //Transform the alignment json into a format that makes it easier to render the chart
        transformAlignment = function(data, max) {

          //Split the control and edited into arrays
          controlArr = data.control.split("");
          editedArr = data.edited.split("");

          //Fill the arrays so they are the same length (makes the scrubber scroll display correctly)
          for (var i = controlArr.length; i < max; i++) {
            controlArr.push(null);
          }
          for (var i = editedArr.length; i < max; i++) {
            editedArr.push(null);
          }

          //Combine the arrays into one array of objects
          data.combined = controlArr.map(function(ctrlVal, index){
            return {
              control: ctrlVal,
              edited: editedArr[index],
              position: index + 1, //For the position labels
              match: ctrlVal === editedArr[index] ? ctrlVal : null //For the vertical match lines
            };
          });
          
          return data;
        }

        getAll = function() {
          return  $http({
            method: 'GET',
            url: scope.filePath + '/all.json'
          });
        }

        getWindowed = function() {
          return  $http({
            method: 'GET',
            url: scope.filePath + '/windowed.json'
          });
        }

        //Show loader
        scope.detailLoading = true;

        $q.all([getAll(), getWindowed()]).then(function (results) {
          
          var all = results[0].data;
          var windowed = results[1].data;

          //Get the larger array so we can fill the shorter one to match in length
          var max = Math.max(all.control.length, windowed.control.length)

          //Transform
          scope.all = transformAlignment(all, max);
          scope.windowed = transformAlignment(windowed, max);

          //Hide loader
          scope.detailLoading = false;

        });
            


      }
    }
  }]);

angular.module('app')
  .directive('analyzeBatchForm', function(){
    return {
      restrict: 'EA',
      scope: false,
      templateUrl: './views/analyze/_analyze-batch-form.0fc0d4fb.html',
      controller: ['$scope', '$state', '$timeout', 'userStorage', 'envConfig', 'Upload', 'analyze', function ($scope, $state, $timeout, userStorage, envConfig, Upload, analyze) {

        //Get options from localstorage
        $scope.analyzeOptions = userStorage.analyzeOptions;

        //For batch form submission, takes the excel file and zip from the file fields
        $scope.batchFormSubmit = function(xlsx, zip) {

          if($scope.analyzeBatchForm.$valid) {

            ///Send the files
            Upload.upload({
              url: envConfig.APIURL+'/analysis/work-order/',
              data: {
                definition_xlsx: xlsx,
                sanger_zip: zip,
                batch_upload: true
              }
            }).then(function (response) {

              //Success! We now have a work order
              var workOrder = response.data;
              console.log('Work Order ==>', workOrder.id, workOrder);

              $timeout(function () {

                //Clear the cache and run a new analysis from the work order
                analyze.clearCache();
                analyze.startAnalysis(workOrder.id)
                  .then(function(response) {

                    //Success! We now have a run
                    var run = response.data;
                    console.log('Analysis Run ==>', run.id, run)

                    //Add the run to localstorage for the recents menu
                    $scope.analyzeOptions.recentRuns.unshift({
                      xlsxName: xlsx.name, 
                      zipName: zip.name, 
                      id: run.id, 
                      timestamp: run.created,
                      status: run.status
                    });

                    //Go to the results page for the run
                    $state.go('analyze.results', {run_id:run.id});

                  })
                  .catch(function(error) {

                    console.log('Analysis Error ==>', error)
                    $state.go('error', { 
                      message: 'An unknown error occurred, please try uploading again in 15 minutes.', 
                      details: {
                        response: error,
                        work_order_id: workOrder.id
                      },
                      backTo: 'analyze' 
                    });

                  });

              });

            }, function (response) {

              console.log('Upload Error ==>', response);

              //If it's a bad request we have messages we can display on the form
              if (response.status == 400 && (response.data.definition_xlsx && response.data.sanger_zip)) {

                //Set errors for display
                $scope.batchApiErrors = response.data;

                //Manually set form field(s) as invalid
                if($scope.batchApiErrors.definition_xlsx.length) {
                  $scope.analyzeBatchForm.xlsx.$setValidity('apiError', false);
                }
                if($scope.batchApiErrors.sanger_zip.length) {
                  $scope.analyzeBatchForm.zip.$setValidity('apiError', false);
                }

                //Reset progress
                $scope.progress = null;

              //Any other error gets a generic message
              } else {
                $state.go('error', { 
                  message: 'An unknown error occurred, please try uploading again in 15 minutes.', 
                  details: {
                    response: response
                  },
                  backTo: 'analyze' 
                });
              }

            }, function (evt) {
              //Update the progress (for display on submit button)
              $scope.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
            });

            
          }
        }

        //Manually make file fields valid (used when those fields change)
        $scope.makeBatchFieldsValid = function() {
          if($scope.batchApiErrors) {
            $scope.analyzeBatchForm.xlsx.$setValidity('apiError', true);
            $scope.analyzeBatchForm.zip.$setValidity('apiError', true);
            $scope.batchApiErrors = null;
          }
        }


      }]
    }
  });
angular.module('app')
  .directive('analyzeContributionsChart', ['$http', '$timeout', function($http, $timeout) {
    return {
      restrict: 'EA',
      scope: {
        filePath: '=',
        sample: '='
      },
      templateUrl: './views/analyze/_analyze-contributions-chart.7ab8bf44.html',
      link: function(scope, element, attrs) {

        //Transform the contribs json into a format that makes it easier to render the table
        transformContribs = function(contribsList) {

          //Split the sequences into arrays, keep the string for the copy to clipboard button
          return contribsList.map(function(obj, index){
            obj.human_readable_str = obj.human_readable;
            obj.human_readable = obj.human_readable.split("");
            obj.knockin = isKnockin(obj);
            return obj;
          });

        }

        //Show loader
        scope.detailLoading = true;

        //Checks if a contrib is a knockin/hdr
        function isKnockin(contrib) {
          var details = contrib.indel.details;
          if (details && details.length > 0) {
            return details[0].label === 'hdr';
          }
          return false;
        }

        //Checks for knocking/hdr data in contrib list
        function containsKnockin (contribList) {
          return contribList.filter(isKnockin).length > 0;
        }

        $http({
          method: 'GET',
          url: scope.filePath + '/contribs.json'
        }).then(
          function success(response) {

            //Open ICE changed contrib format so this handles both formats for backwards compatibility
            contribsList = response.data.contribs_list ? response.data.contribs_list : response.data;

            //Transform the data
            scope.contribs = transformContribs(contribsList);

            scope.multiplex = response.data.multiplex;

            //Delete if not used, leaving because could be useful for HDR label on contribs
            scope.containsHDR = containsKnockin(contribsList);

            //Hide the loader
            scope.detailLoading = false;
          },
          function failure(reason) {
            scope.detailLoading = false;
            console.log('Can\'t fetch data.');
          }
        );
           

      }
    }
  }]);

angular.module('app')
  .directive('analyzeDiscordOutcomesChart', ['$q', '$http', 'Flash', function($q, $http, Flash) {
    return {
      restrict: 'EA',
      scope: {
        filePath: '='
      },
      templateUrl: './views/analyze/_analyze-discord-outcomes-chart.22372826.html',
      link: function(scope, element, attrs) {

        //c3js default config for discord chart
        scope.discordChart = {
          data: {
            json: {},
            colors: {
              Control: '#F07700',
              Edited: '#20D340',
            },
            type: 'line'
          },
          grid: {
            x: {
              lines: []
            },
            y: {
              lines: []
            }
          },
          axis: {
            x: {
              label: {
                text: 'Sanger coordinates (bp)',
                position: 'outer-right'
              },
              padding: {
                left:20
              },
              tick: {
                //This breaks the tooltip https://github.com/c3js/c3/issues/1222
                //Workaround is to hide ticks with CSS
                //values: [100, 200, 300, 400, 500, 600, 700, 800, 900, 1000]
              }
            },
            y: {
              label: {
                text:  'Discordance',
                position: 'outer-top'
              },
              tick: {
                values:[0, 0.2, 0.4, 0.6, 0.8, 1]
              }
            }
          },
          regions: [],
          point: {
            show: false
          },
          tooltip: {
            format: {
              value: function (value) { return parseFloat(value).toFixed(2); } //Round the tooltip values
            }
          },
          onrendered: function(d){

            //Prevent cut site labels from overlapping
            var prevLine = 0;
            var offset = -25;
            d3.selectAll('.cut-site').select('text').each(function(){

              var elem = d3.select(this);
              var thisLine = elem.attr('y');
              //If they're too close horizontally, scoot label down
              if(prevLine > thisLine - 15) {
                elem.attr('dx', offset);
                offset = offset - 25;
              } else {
                offset = -25;
              }
              prevLine = thisLine
            });

          }
        };

        //c3js default config for outcomes chart
        scope.outcomesChart = {
          padding: {
            bottom: 20
          },
          data: {
            json: [],
            colors: {
               percent: '#00D1ED'
            },
            keys: {
              x: 'indel',
              value: ['percent']
            },
            names: {
              'indel': 'Indel',
              'percent': 'Percentage of Indel'
            },
            type: 'bar'
          },
          axis: {
            y: {
              max: 25, // overwritten after data is fetched
              label: {
                text: 'Percentage of this indel in mixture',
                position: 'outer-top'
              }
            },
            x: {
              label: {
                text: 'Indel Size',
                position: 'outer-right'
              }
            }
          },
          legend: {
            show: false
          },
          tooltip: {
            format: {
              value: function (value) { return parseFloat(value).toFixed(2)+'%'; } //Round the tooltip values
            }
          },
          bar: {
            width: {
              ratio: 0.5
            }
          }
        };

       scope.hdrOutcomeChart = {
         padding: {
           bottom: 30
         },
         data: {
          json: [],
            colors: {
               percent: '#00AEEF',
            },
            keys: {
              x: 'insert',
              value: ['percent']
            },
            names: {
              'insert': 'Insert',
              'percent': 'Percentage of Insert'
            },
            type: 'bar'
         },
         axis: {
           y: {
             max: 25, // overwritten after data is fetched
             show: false
           },
           x: {
             type: 'category',
             categories: ['hdr']
           }
         },
         legend: {
           show: false,
         },
         tooltip: {
           format: {
             value: function (value) { return parseFloat(value).toFixed(2)+'%'; } //Round the tooltip values
           }
         },
         bar: {
           width: {
             ratio: 0.06
           }
         }
       };

       //Show the loader
       scope.detailLoading = true;

       scope.showHdrOutcome = false;

       function jsonNaNSafeTransform(data) {
         // ICE open sometimes puts NaN in json files
         var safeResult = JSON.parse(data.replace(/\bNaN\b/g, 'null'));
         return safeResult;
       }

        //Get the chart json and display it on the charts
        $http({
          method: 'GET',
          url: scope.filePath+'/plot.json',
          transformResponse: jsonNaNSafeTransform  // angular default transform does not handle NaN in json
        }).then(
          function success(response) {
            var discord = response.data.discord_plot;
            var outcomes = response.data.editing_outcomes;

            //Set the discord chart data
            scope.discordChart.data.json = {
              Control: discord.control_discord,
              Edited: discord.edited_discord
            };

            //Set up the various vertical grid lines and labels
            scope.discordChart.grid.x.lines = [
              {value: discord.inf_start, text: 'Inference Window', position: 'end',  class: 'inference'},
              {value: discord.aln_start, text: 'Alignment Window', position: 'start',  class: 'alignment'}
            ];

            if(discord.guide_targets != undefined) {
              //Push cut site x lines
              discord.guide_targets.forEach(function(target, index) {

                if(discord.guide_targets.length > 1) {
                  var labelText = target.label;
                } else {
                  var labelText = '';
                }

                scope.discordChart.grid.x.lines.push({
                  value: target.ctrl_cutsite, 
                  text: labelText, 
                  position: 'end',  
                  class: 'cut-site'
                });

              });
            } else {
              //Legacy pre-multiplex cut_site value
              scope.discordChart.grid.x.lines.push({
                value: discord.cut_site, 
                text: 'Cut Site', 
                position: 'end',  
                class: 'cut-site'
              });

            }

            //Highlight the inference and alignment window regions
            scope.discordChart.regions = [
              {axis: 'x', start: discord.inf_start, end: discord.inf_end, class: 'inference'},
              {axis: 'x', start: discord.aln_start, end: discord.aln_end, class: 'alignment'},
            ];

            //Show the rsq value as a horizontal grid line label, the line itself is hidden with css
            scope.discordChart.grid.y.lines = [
             {value: 1, text: 'r²='+response.data.r_sq, position: 'end',  class: 'rsquared'}
            ];

            //Transform the outcomes data for c3js
            var outcomesArr = [];
            for (var indel in outcomes) {
              outcomesArr.push({indel: indel, percent: outcomes[indel]})
            };


            //Set the outcomes chart data
            formattedOutcomes = {};
            formattedOutcomes = outcomesArr;
            scope.outcomesChart.data.json = formattedOutcomes;
            function calcYAxisMax(outcomes, hdrPercent) {
              var maxYVal = Math.max.apply(null, outcomes.map(function(outcome) {return outcome.percent}));
              if (hdrPercent && hdrPercent > maxYVal) {
                maxYVal = hdrPercent
              }
              return Math.ceil(maxYVal/5) * 5; // round up to closest value divisible by 5
            }

            //yAxisMax is used to keep the HDR and non HDR indel graphs visually comparable
            var yAxisMax = calcYAxisMax(formattedOutcomes, response.data.hdr_percentage);
            scope.outcomesChart.axis.y.max = yAxisMax;
            if (response.data.hdr_percentage) {
              scope.showHdrOutcome = true;
              scope.hdrOutcomeChart.data.json = [{ insert: 'hdr', percent: response.data.hdr_percentage }];
              scope.hdrOutcomeChart.axis.y.max = yAxisMax;
            }

            //Hide the loader
            scope.detailLoading = false;

          },
          function failure(reason) {
            scope.detailLoading = false;
            console.log('Can\'t fetch data.');
          }
        );

      }
    }
  }]);

angular.module('app')
  .directive('analyzeDonorAlignmentChart', ['$q', '$http', '$timeout', function($q, $http, $timeout) {
    return {
      restrict: 'EA',
      scope: {
        filePath: '='
      },
      templateUrl: './views/analyze/_analyze-donor-alignment-chart.b6a2b762.html',
      link: function(scope, element, attrs) {

        //Transform the alignment json into a format that makes it easier to render the chart
        transformAlignment = function(data, max) {

          //Split the control and edited into arrays
          controlArr = data.control.toUpperCase().split("");
          editedArr = data.edited.toUpperCase().split("");

          //Fill the arrays so they are the same length (makes the scrubber scroll display correctly)
          for (var i = controlArr.length; i < max; i++) {
            controlArr.push(null);
          }
          for (var i = editedArr.length; i < max; i++) {
            editedArr.push(null);
          }

          //Combine the arrays into one array of objects
          data.combined = controlArr.map(function(ctrlVal, index){
            return {
              control: ctrlVal,
              edited: editedArr[index],
              position: index + 1, //For the position labels
              match: ctrlVal === editedArr[index] ? ctrlVal : null //For the vertical match lines
            };
          });

          return data;
        }

        getDonorAlignment = function() {
          return $http({
            method: 'GET',
            url: scope.filePath + '/donor.json'
          });
        }

        //Show loader
        scope.detailLoading = true;

        $q.all([getDonorAlignment()]).then(function (results) {

          var donorAlignment = results[0].data;

          //Transform
          scope.donorAlignment = transformAlignment(donorAlignment, donorAlignment.control.length)

          //Hide loader
          scope.detailLoading = false;

        });
      }
    }
  }]);
angular.module('app')
  .directive('hubspotDownloadForm', ['analyze', 'userStorage', function (analyze, userStorage) {
      return {
        restrict: 'EA',
        scope: {
          downloadEmail: '='
        },
        link: function (scope, element, attrs) {

          hbspt.forms.create({
            css: '',
            portalId: '2418554',
            formId: attrs.formId,
            target: '#'+attrs.id,
            formInstanceId: attrs.id,
            submitButtonClass: 'btn btn-primary btn-lg '+attrs.btnClass,
            errorClass: 'has-error',
            errorMessageClass: 'help-block has-error',
            inlineMessage: ' ', //Hide the default hubspot message
            onFormReady: function($form) {
              $form.find('.hs_run_id').find('input').val(attrs.runId);
            },
            //When the hubspot form is submitted, request a download for that user
            onFormSubmit: function($form) {
              //Grab the email address from the form
              var email = $form.find('.hs_email').find('input').val();

              console.log('Requesting download for:', email, attrs.runId);

              analyze.sendAnalysisDownload(attrs.runId, email)
                .then(function(response) {

                  //Success! We have a download requested
                  var download = response.data;
                  console.log('Download ==>', download);

                  //Set some scope variable right away
                  scope.downloadEmail.status = download.status;
                  scope.downloadEmail.email = download.email;

                  //Store the returned download id with the run in localstorage
                  userStorage.analyzeOptions.runDownloads[attrs.runId] = download.id;

                })
                .catch(function(error) {
                  console.log('Error', error)
                  scope.downloadEmail.status = 'error';
                });
            }
          });
          
        }
      };
    }])
angular.module('app')
  .directive('analyzeLoaderSine', function(){
    return {
      restrict: 'EA',
      scope: {
        ice: '=',
        successfulResults: '=',
        status: '='
      },
      templateUrl: './views/analyze/_analyze-loader-sine.59a91eae.html',
      controller: ['$scope', '$transitions', 'userStorage', function($scope, $transitions, userStorage) {
        var waves;
        var canvas = document.createElement('canvas');
        canvas.id = 'loader-canvas';

        document.getElementById('loader-bg-sine').append(canvas);

          waves = new SineWaves({
            el: canvas,
            
            speed: 4,
            //rotate: 90,

            width: function() {
              return window.innerWidth;
            },
            
            height: function() {
              return window.innerHeight;
            },
            
            ease: 'SineInOut',
            
            wavesWidth: '150%',
            
            waves: [
              {
                timeModifier: 2,
                lineWidth: 1,
                amplitude: -50,
                wavelength: 50,
                strokeStyle: 'rgba(255, 255, 255, .45)'
              },
              {
                timeModifier: 1,
                lineWidth: 1,
                amplitude: -100,
                wavelength: 100,
                strokeStyle: 'rgba(255, 255, 255, .35)'
              },
              {
                timeModifier: 0.5,
                lineWidth: 1,
                amplitude: -200,
                wavelength: 200,
                strokeStyle: 'rgba(255, 255, 255, .25)'
              },
              {
                timeModifier: 0.25,
                lineWidth: 1,
                amplitude: -400,
                wavelength: 400,
                strokeStyle: 'rgba(255, 255, 255, .15)'
              },
              {
                timeModifier: 4,
                lineWidth: 2,
                amplitude: -25,
                wavelength: 25,
                strokeStyle: 'rgba(32, 211, 64, 1)'
              }
            ],
          
          });

        // Logic for showing loading screen
        $scope.$watchCollection(['status.loading','ice.status'], function (loading, status) {
          if(loading == false && status == 'completed') {
            console.log('Done loading, kill sinewaves')
            killWaves();
          }
        });

        $transitions.onSuccess({ to: '**', from: 'analyze.results' }, function(transition){
          killWaves();
        });

        $scope.$watch('successfulResults.length', function (resultsLength) {
          if(userStorage.debugOptions.iceloader == 'combo' && resultsLength > 0) {
            console.log('Combo loader, kill sinewaves');
            killWaves();
          }
        });


        killWaves = function() {
          if(waves != undefined) {
            if(waves.running) {
              waves.running = false;
              waves.update()
            } 
          };
        }

      }]
    }
  });
angular.module('app')
  .directive('analyzeLoader', function(){
    return {
      restrict: 'EA',
      scope: {
        ice: '=',
        status: '='
      },
      templateUrl: './views/analyze/_analyze-loader.8ed86e00.html',
      controller: ['$scope', '$transitions', function($scope, $transitions) {
        var fallTimer;
        var canvas = document.createElement('canvas');
        canvas.id = 'loader-canvas';

        document.getElementById('loader-bg').append(canvas);

        //var canvas = angular.element(document.getElementById('loader-bg'))[0];
        var context = canvas.getContext('2d');
        var rectWidth = 60;
        var rectPadding = 40;
        var color = {
          r:141,
          g:199,
          b:63
        };

        //var color = {
        //  r:0,
        //  g:174,
        //  b:239
        //};

        var time = 24;

        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;

        start(); 
   

        function start() {
          if (angular.isDefined(fallTimer)) {
            clearInterval(fallTimer);
            fallTimer = undefined;
          }

          fallTimer= setInterval(function() {
                context.clearRect(0, 0, canvas.width, canvas.height);
                context.beginPath();
                context.rect(0,0,canvas.width,canvas.height,"white");
                context.fillStyle = '#1D1D1D';
                context.fill();
                context.stroke();
                if (newRect % objects.time === 0) {
                    var xloc = getRandomIntInclusive(0,window.innerWidth/rectWidth)*(rectWidth+rectPadding)+rectPadding;
                    objects.centers.push([xloc,0]);
                    objects.randomizer.push(Math.random() * (1 - 0.3) + 0.3);
                    objects.stepSize.push(Math.random(0,1)*3);
                    objects.number++;
                }
                newRect++;
                objects.moveDown();
            },15);

        }

        var newRect = 0;
        var objects = {
            number : 0,
            centers : [], //array of locations
            colors : [],
            time : time,
            stepSize : [],
            randomizer: [],
            moveDown : function() {
                var i = 0;
                var removals = [];
                for (i=0;i<objects.number;i++) {
                    objects.centers[i][1] = objects.centers[i][1] + objects.stepSize[i];
                    if (objects.centers[i][0] > window.innerWidth - rectWidth - rectPadding) {
                        removals.push(i);
                    }
                }
                for (j=0;j<removals.length;j++) {
                    objects.centers.splice(removals[j],1);
                    objects.stepSize.splice(removals[j],1);
                    objects.number = objects.number -1;
                }
                objects.drawRects();
            },
            drawRects : function() {
                for (i=0;i<objects.number;i++) {
                    var rectHeight = objects.stepSize[i]*200;
                    var rectX = objects.centers[i][0];
                    var rectY = objects.centers[i][1]-rectHeight;

                    context.beginPath();
                    context.rect(rectX,rectY, rectWidth,rectHeight);
                    var gradient = context.createLinearGradient(objects.centers[i][0],objects.centers[i][1]-(objects.stepSize[i]*200), 0, window.innerHeight);
                  
                    gradient.addColorStop(0, 'rgba('+color.r+','+color.g+','+color.b+',0)');
                    gradient.addColorStop(1, 'rgba('+color.r+','+color.g+','+color.b+',0.6)');
                    context.fillStyle = gradient
                    
                    context.fill();
                  
                    // Kills performance even more
                    //context.shadowBlur = 10;
                    //context.shadowColor = 'rgba('+color.r+','+color.g+','+color.b+',1)';
                }
                for (i=0;i<objects.number;i++) {
                    context.beginPath();
                    context.rect(objects.centers[i][0],objects.centers[i][1],
                                rectWidth,(rectWidth/3)*objects.randomizer[i]);
                    context.fillStyle = 'rgba('+color.r+','+color.g+','+color.b+','+objects.randomizer[i]+')'
                    context.fill();
                  

                }
            },
        };

        function getRandomIntInclusive(min, max) {
          min = Math.ceil(min);
          max = Math.floor(max);
          return Math.floor(Math.random() * (max - min + 1)) + min;   
        }

        function roundRect(ctx, x, y, width, height, radius, fill, stroke) {
          if (typeof stroke == 'undefined') {
            stroke = false;
          }
          if (typeof radius === 'undefined') {
            radius = 3;
          }
          if (typeof radius === 'number') {
            radius = {tl: radius, tr: radius, br: radius, bl: radius};
          } else {
            var defaultRadius = {tl: 0, tr: 0, br: 0, bl: 0};
            for (var side in defaultRadius) {
              radius[side] = radius[side] || defaultRadius[side];
            }
          }
          ctx.beginPath();
          ctx.moveTo(x + radius.tl, y);
          ctx.lineTo(x + width - radius.tr, y);
          ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
          ctx.lineTo(x + width, y + height - radius.br);
          ctx.quadraticCurveTo(x + width, y + height, x + width - radius.br, y + height);
          ctx.lineTo(x + radius.bl, y + height);
          ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
          ctx.lineTo(x, y + radius.tl);
          ctx.quadraticCurveTo(x, y, x + radius.tl, y);
          ctx.closePath();
          if (fill) {
            ctx.fill();
          }
          if (stroke) {
            ctx.stroke();
          }

        }

        $scope.$watchCollection(['status.loading','ice.status'], function (loading, status) {

          if(loading == false && status == 'completed') {

            console.log('Done loading, kill interval', fallTimer)
            // If we're done loading kill animation
            if (angular.isDefined(fallTimer)) {
              clearInterval(fallTimer);
              fallTimer = undefined;
            }

            document.getElementById('loader-bg').removeChild(document.getElementById('loader-canvas'))

          }
        });

        $transitions.onSuccess({ to: '**', from: 'analyze.results' }, function(transition){
          if (angular.isDefined(fallTimer)) {
            clearInterval(fallTimer);
            fallTimer = undefined;
          }
        });

      }]
    }
  });
angular.module("app").directive("analyzeSingleForm", function () {
  return {
    restrict: "EA",
    scope: false,
    templateUrl: "./views/analyze/_analyze-single-form.d1b5e32b.html",
    controller: [
      "$scope",
      "$state",
      "$location",
      "$timeout",
      "$document",
      "$filter",
      "Flash",
      "userStorage",
      "envConfig",
      "Upload",
      "analyze",
      function ($scope, $state, $location, $timeout, $document, $filter, Flash, userStorage, envConfig, Upload, analyze) {
        //Used for BETA testing knockin
        $scope.showKnockin = true;

        //Get options from localstorage
        $scope.analyzeOptions = userStorage.analyzeOptions;

        //Set experiments to be empty
        $scope.experiments = [];

        //Set default nuclease to cas9
        $scope.nuclease = "spcas9";

        //Clears out any experiments and the work order stored in localstorage
        $scope.resetExperiments = function () {
          console.log("Resetting experiments");
          $scope.analyzeOptions.workOrder = null;
          $scope.experiments = [];
        };

        //Reset the form so it's ready to be used to add another sample
        $scope.resetForm = function () {
          console.log("Resetting form");
          $scope.progress = null;
          $scope.controlFile = null;
          $scope.experimentFile = null;
          $scope.guides = null;
          $scope.donorSeq = null;
          $scope.label = null;
          $scope.nuclease = "spcas9";
          $scope.multiplex = false;
          $scope.analyzeSingleForm.$setPristine();
          $scope.analyzeSingleForm.$setUntouched();
        };

        //For single sample form submissions, takes the ab1 files guide and label
        $scope.singleFormSubmit = function (controlFile, experimentFile, guides, donorSeq, label, nuclease) {
          if ($scope.analyzeSingleForm.$valid) {
            var data = {
              control_file: controlFile,
              experiment_file: experimentFile,
              input_guides: guides.join(","),
              label: label,
              nuclease: nuclease,
            };

            //Only add donor seq to request if it has a value
            if (donorSeq) {
              data.donor_seq = donorSeq;
            }

            //If there is a work order stored locally, we send it along with the request
            if ($scope.analyzeOptions.workOrder) {
              data.work_order = $scope.analyzeOptions.workOrder;
            }

            var request = {
              url: envConfig.APIURL + "/analysis/experiment/",
              data: data,
            };

            //Send the data
            Upload.upload(request).then(
              function (response) {
                //Success! The data was sent to an existing or new work order
                console.log("Experiment Sent to Work Order ==>", response.data.work_order, response.data);

                $timeout(function () {
                  //Set the locally stored work order id
                  $scope.analyzeOptions.workOrder = response.data.work_order;

                  //Reset the form so another sample can be uploaded
                  $scope.resetForm();
                  //Get experiments associated with current work order
                  $scope.getExperiments();
                  //Scroll down so the work orders are visible on short screens
                  $document.duScrollToElementAnimated(angular.element(document.getElementsByClassName("main-form")[0]), 75);
                });
              },
              function (response) {
                console.log("Upload Error ==>", response);

                //If it's a bad request we have messages we can display on the form
                if (
                  (response.status == 400 &&
                    (response.data.label ||
                      response.data.input_guides ||
                      response.data.donor_seq ||
                      response.data.control_file ||
                      response.data.experiment_file)) ||
                  response.data.nuclease
                ) {
                  //Set errors for display
                  $scope.singleApiErrors = response.data;

                  //Manually set form field(s) as invalid

                  if ($scope.singleApiErrors.label) {
                    $scope.analyzeSingleForm.label.$setValidity("apiError", false);
                  }
                  if ($scope.singleApiErrors.input_guides) {
                    $scope.analyzeSingleForm.guides.$setValidity("apiError", false);
                  }
                  if ($scope.singleApiErrors.donor_seq) {
                    $scope.analyzeSingleForm.donorSeq.$setValidity("apiError", false);
                  }
                  if ($scope.singleApiErrors.control_file) {
                    $scope.analyzeSingleForm.control.$setValidity("apiError", false);
                  }
                  if ($scope.singleApiErrors.experiment_file) {
                    $scope.analyzeSingleForm.edit.$setValidity("apiError", false);
                  }
                  if ($scope.singleApiErrors.nuclease) {
                    $scope.analyzeSingleForm.nuclease.$setValidity("apiError", false);
                  }

                  //Reset progress
                  $scope.progress = null;

                  //Any other error gets a generic message
                } else {
                  $state.go("error", {
                    message: "An unknown error occurred, please try uploading again in 15 minutes.",
                    details: {
                      response: response,
                      work_order_id: $scope.analyzeOptions.workOrder,
                    },
                    backTo: "analyze",
                  });
                }
              },
              function (evt) {
                //Update the progress (for display on submit button)
                $scope.progress = Math.min(100, parseInt((100.0 * evt.loaded) / evt.total));
              }
            );
          }
        };

        //Manually make file fields valid (used when those fields change)
        $scope.makeSingleFieldsValid = function () {
          if ($scope.singleApiErrors) {
            $scope.analyzeSingleForm.label.$setValidity("apiError", true);
            $scope.analyzeSingleForm.guides.$setValidity("apiError", true);
            $scope.analyzeSingleForm.donorSeq.$setValidity("apiError", true);
            $scope.analyzeSingleForm.control.$setValidity("apiError", true);
            $scope.analyzeSingleForm.edit.$setValidity("apiError", true);
            $scope.analyzeSingleForm.nuclease.$setValidity("apiError", true);
            $scope.singleApiErrors = null;
          }
        };

        //Remove an experiment from the work order
        $scope.deleteExperiment = function (experimentID) {
          //Send request to delete the experiment
          analyze
            .deleteExperiment(experimentID)
            .then(function (response) {
              //It was successful, update the list of experiments
              $scope.getExperiments();
            })
            .catch(function (error) {
              console.log(error);
              Flash.clear();
              Flash.create("danger", "Problem deleting experiment.");
            });
        };

        $scope.runExperimentAnalysis = function () {
          //Clear the cache and run a new analysis from the work order in localstorage
          analyze.clearCache();
          analyze
            .startAnalysis($scope.analyzeOptions.workOrder)
            .then(function (response) {
              $timeout(function () {
                //Success! We now have a run
                var run = response.data;
                console.log("Analysis Run ==>", run.id, run);

                //Add the run to localstorage for the recents menu
                $scope.analyzeOptions.recentRuns.unshift({
                  xlsxName: null,
                  zipName: null,
                  id: run.id,
                  timestamp: run.created,
                  status: run.status,
                });

                //Clear out experiements and reset the form
                $scope.resetExperiments();
                $scope.resetForm();

                //Go to the results page for the run
                $state.go("analyze.results", { run_id: run.id });
              });
            })
            .catch(function (error) {
              console.log("Analysis Error ==>", error);
              $state.go("error", {
                message: "Problem starting analysis from work order.",
                details: {
                  response: error,
                  work_order_id: $scope.analyzeOptions.workOrder,
                },
                backTo: "analyze",
              });
            });
        };

        //Get the experiments associated with the run in localstorage
        $scope.getExperiments = function () {
          if ($scope.analyzeOptions.workOrder != null) {
            analyze.clearCache();
            analyze
              .getWorkOrder($scope.analyzeOptions.workOrder)
              .then(function (response) {
                $scope.experiments = response.data.experiments;
              })
              .catch(function (error) {
                console.log("Experiment Fetch Error ==>", error);
                //If there's a 404 clear out local work order and experiments (mostly for switching between environments)
                if (error.status == "404") {
                  $scope.resetExperiments();
                } else {
                  $state.go("error", {
                    message: "Problem fetching work order experiments.",
                    details: {
                      response: error,
                      work_order_id: $scope.analyzeOptions.workOrder,
                    },
                    backTo: "analyze",
                  });
                }
              });
            //If there's no work order, reset experiments
          } else {
            $scope.experiments = [];
          }
        };

        //Check if a label already exists in the experiments
        $scope.isLabelUnique = function (label) {
          return $filter("filter")($scope.experiments, { label: label }, true).length == 0;
        };

        //Fill the label field with the experiment filename if it's not already filled
        $scope.autoFillLabel = function () {
          if ($scope.experimentFile && !$scope.label) {
            $scope.label = $scope.experimentFile.name.replace(".ab1", "");
          }
        };

        $scope.$watchCollection("guides", function (newGuides, oldGuides) {
          if (newGuides != undefined) {
            //If there are non-words, spaces, line breaks, etc, split
            newGuides = newGuides.map(function (x) {
              return x.split(/[\W\s\t\h\r\n]+/g);
            });
            //Smash array, remove empty items
            newGuides = [].concat.apply([], newGuides).filter(Boolean);

            //Remove dupes
            newGuides = newGuides.filter(function (value, index) {
              return newGuides.indexOf(value) == index;
            });

            //If there's more than one we indicate
            if (newGuides.length > 1) {
              $scope.multiplex = true;
            } else {
              $scope.multiplex = false;
            }

            $scope.guides = newGuides;
          }
        });

        $scope.$watch("multiplex", function (newMultiplex) {
          if (newMultiplex) {
            $scope.$broadcast("focusMultiplex");
          }
        });

        $scope.$watch("donorSeq", function (newDonorSeq) {
          if (newDonorSeq) {
            $scope.isKnockin = true;
          } else {
            $scope.isKnockin = false;
          }
        });

        $scope.validateGuidesArray = function (guides) {
          $scope.analyzeSingleForm.guides.$setValidity("minlength", true);
          $scope.analyzeSingleForm.guides.$setValidity("maxlength", true);
          $scope.analyzeSingleForm.guides.$setValidity("pattern", true);
          $scope.analyzeSingleForm.guides.$setValidity("maxguides", true);

          if (guides != undefined) {
            $scope.guideErrors = [];

            if (guides.length > 3) {
              $scope.analyzeSingleForm.guides.$setValidity("maxguides", false);
            }

            guides.forEach(function (guide, index) {
              $scope.guideErrors.push({
                minlength: false,
                maxlength: false,
                pattern: false,
              });
              if (guide.length < 10 && guide.length > 0) {
                $scope.analyzeSingleForm.guides.$setValidity("minlength", false);
                $scope.guideErrors[index].minlength = true;
              }
              if (guide.length > 50) {
                $scope.analyzeSingleForm.guides.$setValidity("maxlength", false);
                $scope.guideErrors[index].maxlength = true;
              }
              if (!/^[ACTGUactgu]+$/.test(guide) && guide.length > 0) {
                $scope.analyzeSingleForm.guides.$setValidity("pattern", false);
                $scope.guideErrors[index].pattern = true;
              }
            });
          }
          return true;
        };

        $scope.multiplexClasses = function (errors) {
          var errorClasses = "";
          if (errors) {
            errors.forEach(function (error, index) {
              if (error.minlength || error.maxlength || error.pattern) {
                errorClasses += " guide-error-" + index;
              }
            });
          }
          return errorClasses;
        };

        //Get experiments on initial load
        $scope.getExperiments();
      },
    ],
  };
});

app.directive('copyButton', [function () {
  return {
    restrict: 'EA',
    replace: true,
    template: '<button class="btn btn-link" ngclipboard ngclipboard-success="copySuccess()" data-clipboard-text=""><i class="material-icons md-16">content_copy</i></button>',
    controller: ['$scope', 'Flash', function($scope, Flash) {
       $scope.copySuccess = function() {
        Flash.clear();
        Flash.create('success', 'Sequence copied to clipboard.', 3000);
      }
    }]
  };
}]);
app.directive('debugMenu', [function () {
  return {
    restrict: 'EA',
    replace: true,
    templateUrl: './views/shared/_debug-menu.aae0e8c1.html',
    controller: ['$scope', 'userStorage', 'analyze', function($scope, userStorage, analyze) {
      $scope.userStorage = userStorage;
    }]
  };
}]);
angular.module('app')
  .directive('embedTrustedContent', ['$http', '$sce', function($http, $sce) {
    return {
      restrict: 'EA',
      priority: -1,
      link: function (scope, element, attrs) {
        //Set the element html to be the loader
        element.html('<div class="content-centered"><div class="logo-loader" ><div><div></div><div></div><div></div><div></div></div></div></div>')
        
        //Request to get the content
        $http({
          method: 'GET',
          url: attrs.contentSrc,
        }).then(
          function success(response) {
            //Set the element html to be the response
            element.html($sce.trustAsHtml(response.data));
          },
          function failure(reason) {
            console.log(reason);
            //On error set element html error message
            element.html('<div class="content-centered"><i class="material-icons md-14">error_outline</i> Failed to load data</div>')
          }
        );
      }
    };
  }])
// Miscellaneous helper directives

angular.module('app')
  // The animated logo loader
  .directive('logoLoader', [function () {
    return {
      restrict: 'EA',
      template: '<div class="logo-loader" ><div><div></div><div></div><div></div><div></div></div></div>'
    };
  }])
  // Help tooltip elements
  .directive('helpTooltip', [function () {
    return {
      restrict: 'EA',
      scope: {
        text: '@'
      },
      template: '<span class="help-icon" uib-tooltip="{{text}}" tooltip-append-to-body="true" tooltip-placement="auto top"><i class="material-icons md-16">help_outline</i></span></span>'
    };
  }])
  .directive('errorTooltip', [function () {
    return {
      restrict: 'EA',
      scope: {
        text: '@'
      },
      template: '<span class="help-icon" uib-tooltip="{{text}}" tooltip-append-to-body="true" tooltip-placement="auto top"><i class="material-icons md-16">error_outline</i></span></span>'
    };
  }])
  // Back button
  .directive('back', ['$window', function($window) {
    return {
      restrict: 'EA',
      link: function (scope, element, attrs) {
          element.bind('click', function () {
              $window.history.back();
          });
      }
    };
  }])
  // Reload button
  .directive('reload', ['$window', function($window) {
    return {
      restrict: 'EA',
      link: function (scope, element, attrs) {
          element.bind('click', function () {
              $window.location.reload();
          });
      }
    };
  }])
  // Waypoints
  .directive('waypoint', [function(){
    return {
      restrict: 'EA',
      link: function(scope, element, attrs) {
        new Waypoint({
          element: element[0],
          handler: function(direction) {

            if(direction == 'down') {
              element.removeClass('scrolled-to scrolled-to-up').addClass('scrolled-to scrolled-to-down');
            } else if(direction == 'up') {
              element.removeClass('scrolled-to scrolled-to-down').addClass('scrolled-to-up');
            }
            
          },
          offset: attrs.offset || '50%'
        })
      }
    }
  }])
  // Rounding filter
  .filter('roundpercent', function() {
    return function(input) {
      if(input == null) {
        return 'N/A';
      }
      else {
        return Math.round(input*10000)/100+'%';
      }
    };
  })
  // Confidence CSS class
  .filter('confclass', function() {
    return function(sample) {
      if(sample != undefined) {
        if(sample.rsq != undefined) {
          return 'conf-'+Math.round(sample.rsq * 100);
        }
      }
    };
  })

  .filter('breakcommas', function() {
    return function(input) {
      return input.replace(/,/g,'<br>')
    };
  })

  .filter('formatContribLabel', function() {
    return function(input) {
      if (input === 'dropout') {
        return 'fragment deletion';
      }
      return input;
    }
  })

  //If not negative, add plus to string, also capitalize non ints
  .filter('addplus', function() {
    return function(input) {
      if (isNaN(parseInt(input))) {
        return input.toUpperCase()
      } else if (parseInt(input) > 0) {
        return '+' + input;
      } else {
        return input;
      }
    };
  })

  .filter('strippunc', function() {
    return function(input) {
      return input.replace(/[^\w\s]|_/g, "").replace(/\s+/g, " ");
    };
  })

  .directive('selectOnBlur', function($document) {
  return {
    require: 'uiSelect',
    link(scope, elm, attrs, ctrl) {
      let body = $document[0].body,
        handleClick = (evt) => {
          //Look for elm (the ui-select root element) somewhere in the ancestors
          let target = evt.target;
          if (elm === target) {
            return;
          }
          while (target.parentNode) {
            target = target.parentNode;
            if (elm === target) {
              return;
            }
          }

          //Did not exit, therefore user clicked outside of the ui-select
          //only select if open and something is typed
          //Check ctrl.items also, because for ui-select with fixed data, if all possible entries
          //  have already been selected then activeIndex will = 0 but items will be [].
          if (ctrl.open && ctrl.search &&
            (ctrl.items && ctrl.items.length) && (
            ctrl.tagging.isActivated || ctrl.activeIndex >= 0)) {
            ctrl.select(ctrl.items[ctrl.activeIndex]);
          }
        };

      body.addEventListener('click', handleClick);
      scope.$on('$destroy', () => {
        body.removeEventListener('click', handleClick);
      });
    }
  };
});





 
angular.module('app')
  .directive('hubspotForm', [function () {
      return {
        restrict: 'EA',
        link: function (scope, element, attrs) {
          hbspt.forms.create({
            css: '',
            portalId: '2418554',
            formId: attrs.formId,
            target: '#'+attrs.id,
            formInstanceId: attrs.id,
            submitButtonClass: 'btn btn-primary btn-lg '+attrs.btnClass,
            errorClass: 'has-error',
            errorMessageClass: 'help-block has-error',
          });
        }
      };
    }])
app.directive('recentMenu', [function () {
  return {
    restrict: 'EA',
    replace: true,
    templateUrl: './views/shared/_recent-menu.f52c646f.html',
    controller: ['$scope', 'userStorage', 'analyze', function($scope, userStorage, analyze) {

      //Get options from localstorage
      $scope.analyzeOptions = userStorage.analyzeOptions;

      //When the recents menu is opened
      $scope.toggled = function(open) {

        if(open) {

          //Loop through the recent runs
          $scope.analyzeOptions.recentRuns.forEach(function(run, index) {

            //If they're still processing, check if they're done yet
            if(run.status == 'processing') {

              analyze.getAnalysis(run.id)
                .then(function(response) {
                  $scope.analyzeOptions.recentRuns[index].status = response.data.status;
                  if(response.data.completed_time != undefined) {
                    $scope.analyzeOptions.recentRuns[index].timestamp = response.data.completed_time;
                  }
                })
                .catch(function(error) {
                  console.log('ERROR', error);
                });
            }
          });
        }
        
      };

    }]
  };
}]);
app.directive('shareMenu', [function () {
  return {
    restrict: 'EA',
    scope: {
      dropdownClass: '@'
    },
    templateUrl: './views/shared/_share-menu.5e4bf108.html'
  };
}])
angular.module('app')
  .service('analyze', ['$http', '$q', 'envConfig', function ($http, $q, envConfig) {

      var analyzeCache;

      this.startAnalysis = function (workOrderID) {
        console.log(workOrderID)
        var d = $q.defer();

        $http({
          method: 'POST',
          url: envConfig.APIURL+'/analysis/run/',
          data: {
            'work_order': workOrderID
          }
        }).then(
          function success(response) {
              d.resolve(response);
          },
          function failure(reason) {
              d.reject(reason);
          }
        );

        return d.promise;
      }

      this.getWorkOrder = function (workOrderID) {
        console.log(workOrderID)
        var d = $q.defer();

        $http({
          method: 'GET',
          url: envConfig.APIURL+'/analysis/work-order/'+workOrderID
        }).then(
          function success(response) {
              d.resolve(response);
          },
          function failure(reason) {
              d.reject(reason);
          }
        );

        return d.promise;
      }

      this.deleteExperiment = function (experimentID) {
        console.log(experimentID)
        var d = $q.defer();

        $http({
          method: 'DELETE',
          url: envConfig.APIURL+'/analysis/experiment/'+experimentID
        }).then(
          function success(response) {
              d.resolve(response);
          },
          function failure(reason) {
              d.reject(reason);
          }
        );

        return d.promise;
      }

      this.getAnalysis = function (runID) {

        var d = $q.defer();

        if( analyzeCache && analyzeCache.data) {
          console.log('Loading Analysis From Cache');
          d.resolve(analyzeCache);
        }
        else {
          console.log('Loading Analysis');
          $http({
            method: 'GET',
            url: envConfig.APIURL+'/analysis/run/'+runID+'/'
            //url: '/misc/test_data.json' //For testing loading screen
          }).then(
            function success(response) {
                analyzeCache = response;
                d.resolve(analyzeCache);
            },
            function failure(reason) {
                d.reject(reason);
            }
          );
        }

        return d.promise;
      }
      
      this.sendAnalysisDownload = function (runID, email) {

        var d = $q.defer();

        $http({
          method: 'POST',
          url: envConfig.APIURL+'/analysis/download-email/',
          data: {
            'analysis_run': runID,
            'status': 'requested',
            'email': email
          }
        }).then(
          function success(response) {
              d.resolve(response);
          },
          function failure(reason) {
              d.reject(reason);
          }
        );

        return d.promise;
      }

      this.getAnalysisDownloadStatus = function (emailID) {

        var d = $q.defer();

        $http({
          method: 'GET',
          url: envConfig.APIURL+'/analysis/download-email/'+emailID+'/',
        }).then(
          function success(response) {
              d.resolve(response);
          },
          function failure(reason) {
              d.reject(reason);
          }
        );

        return d.promise;
      }

      this.clearCache = function() {
        analyzeCache = null;
      }

      this.getCache = function() {
        return analyzeCache;
      }
      
  }]);
angular.module('app')
  .service('userStorage', ['$sessionStorage','$localStorage', function($sessionStorage, $localStorage) {

    $sessionStorage.$default({
      debugOptions: {
        iceloader: 'combo'
      }
    });

    $localStorage.$default({
      analyzeOptions: {
        recentRuns: [],
        workOrder: null,
        showForm: false,
        activeForm: 0,
        runDownloads: {}
      }
    });


    return {
      debugOptions: $sessionStorage.debugOptions,
      analyzeOptions: $localStorage.analyzeOptions
    }

  }]);
angular.module('app')
  .service('util', function () {

    this.b64toBlob = function(b64Data, contentType, sliceSize) {

      contentType = contentType || '';
      sliceSize = sliceSize || 512;

      var byteCharacters = atob(b64Data);
      var byteArrays = [];

      for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }

      var blob = new Blob(byteArrays, {type: contentType});
      return blob;
      
    }

  });


angular.module('app')
  .controller('analyzeForm', ['$scope', '$state', '$location', '$q', '$timeout', 'userStorage', 'envConfig', 'Upload', 'analyze', function ($scope, $state, $location, $q, $timeout, userStorage, envConfig, Upload, analyze) {

    $scope.analyzeOptions = userStorage.analyzeOptions;

    if ($location.search().knockin) {
      $scope.exampleFilesHref = '/misc/ice_example_files_with_knockin.zip';
      $scope.exampleHref = '/#/analyze/results/example-with-knockin';
    } else {
      $scope.exampleFilesHref = '/misc/ice_example_files.zip';
      $scope.exampleHref = '/#/analyze/results/example';
    }

    //Disallowed characters on both upload forms
    $scope.noDisallowedChars = function(string) {
      return /^([a-zA-Z0-9\_\-\.\+\(\)\; ]+$)/.test(string)
    }

    $scope.disallowedCharsText = 'alphanumeric characters, _, -, ., +, (, ), and ;'

  }]);

angular.module('app')
  .controller('analyzeResultsDetail', ['$scope', '$state', '$stateParams', '$http', '$q', '$filter', '$transitions', 'analyze', 'envConfig', 'Mousetrap',
	function ($scope, $state, $stateParams, $http, $q, $filter, $transitions, analyze, envConfig, Mousetrap) {

    // go back to summary
    Mousetrap.bind(['up', 'k'], function() {
    	$state.go('analyze.results');
    });

    // previous sample
    Mousetrap.bind(['left', 'h'], function() {
    	if($scope.prevSample){
    		$state.go('analyze.results.detail', {sample_label: $scope.prevSample.label});
    	}
    });

    // next sample
    Mousetrap.bind(['right', 'l'], function() {
    	if($scope.nextSample){
    		$state.go('analyze.results.detail', {sample_label: $scope.nextSample.label});
    	}
    });

    //Get all samples so we can populate the dropdown
    analyze.getAnalysis($stateParams.run_id)
      .then(function(response) {
        //Order samples for dropdown so failed ones are on the bottom
        $scope.samples = $filter('orderBy')(response.data.results, 'status', true);

        //Grab the active sample for the dropdown from the stateparams
        $scope.sample = $filter('filter')($scope.samples, {label: $stateParams.sample_label}, true)[0];

        //Sort guides alphabetically by their label (eg make sure 'g1' comes before 'g2')
        $scope.sample.guides.sort(function(g1, g2) {
          return g1.label < g2.label ? -1 : 1;
        })

        //Get the next and previous sample for pager
        var sampleIndex = $scope.samples.indexOf($scope.sample);
        $scope.nextSample = $scope.samples[sampleIndex + 1];
        $scope.prevSample = $scope.samples[sampleIndex - 1];

        //Set active tab
        if($scope.sample.status == 'succeeded') {
          $scope.selectedDetail = 'contributions';
        } else {
          $scope.selectedDetail = 'traces';
        }

      })
      .catch(function(error) {
        console.log('Get Sample Error ==>', error)
        $state.go('error', {
          message: 'Problem fetching samples.', 
          details: {
            response: error,
            run_id: $stateParams.run_id,
          },
          backTo: 'analyze'
        });
      });

    //When a new sample is selected go to it's detail view
    $scope.onSampleChange = function(sample) {
      $state.go('analyze.results.detail', {sample_label: sample.label});
    }

    //Set filepath for displaying SVG and other charts
    $scope.filePath = envConfig.APIURL+'/analysis/run/'+$stateParams.run_id+'/'+$stateParams.sample_label;


    //In case of transitioning from long pages make sure we scroll to top when arriving at detail view
    $transitions.onSuccess({ to: 'analyze.results.detail', from: '**' }, function(transition){
      window.scrollTo(0, 0);
    });

    // on exit, unbind Mousetrap
    $scope.$on("$destroy", function() {
      Mousetrap.unbind(["up", "k"]);
      Mousetrap.unbind(["left", "h"]);
      Mousetrap.unbind(["right", "l"]);
    });

  }]);

angular.module("app").controller("analyzeResults", [
  "$scope",
  "$state",
  "$location",
  "$stateParams",
  "$transitions",
  "$filter",
  "$interval",
  "$anchorScroll",
  "$window",
  "$timeout",
  "Flash",
  "analyze",
  "userStorage",
  "util",
  "c3SimpleService",
  "envConfig",
  function(
    $scope,
    $state,
    $location,
    $stateParams,
    $transitions,
    $filter,
    $interval,
    $anchorScroll,
    $window,
    $timeout,
    Flash,
    analyze,
    userStorage,
    util,
    c3SimpleService,
    envConfig
  ) {
    //Get options and data stored in localstorage
    $scope.debugOptions = userStorage.debugOptions;
    $scope.analyzeOptions = userStorage.analyzeOptions;

    //Show the loading screen
    $scope.status = {
      loading: true
    };

    //Stores download requests
    $scope.downloadEmail = {};

    //Store successful and failed results separately, only successful results go in chart
    $scope.successfulResults = [];
    $scope.failedResults = [];

    $scope.ignoreAnalyzeError = $location.search().ignoreAnalyzeError;

    //c3js default config for summary chart
    $scope.summaryChart = {
      data: {
        json: [],
        keys: {
          value: ["ice", "ko_score", "ki_score"]
        },
        names: {
          ice: "Indel %",
          ko_score: "Knockout-Score",
          ki_score: "Knockin-Score"
        },
        type: "bar",
        selection: {
          grouped: true
        },
        onmouseover: function(d) {
          //Set sample as active and scroll to it in the table
          $scope.$apply(function() {
            $scope.activeSample = $scope.successfulResults[d.index].id;
          });

          $anchorScroll.yOffset = function() {
            return $window.innerHeight * 0.4 + 101;
          };
          $anchorScroll("sample-" + $scope.activeSample);
        },
        onmouseout: function(d) {
          //Unset sample as active
          $scope.$apply(function() {
            $scope.activeSample = "";
          });
        },
        onclick: function(d) {
          //Go to detail view for this sample
          $state.go("analyze.results.detail", {
            sample_label: $scope.successfulResults[d.index].label
          });
        }
      },
      size: {
        height: $window.innerHeight * 0.4 - 30 //Height is 40vh - 30px
      },
      legend: {
        position: "inset",
        inset: {
          anchor: "top-right",
          x: 15,
          y: 15
        }
      },
      color: {
        pattern: ["#00D1ED", "#10d297", "#B84468"]
      },
      axis: {
        x: {
          type: "category",
          padding: {
            left: null
          },
          tick: {
            culling: false,
            format: function(d) {
              //Grab the label for the x axis
              if ($scope.successfulResults[d] != undefined) {
                return $scope.successfulResults[d].label;
              }
            }
          }
        },
        y: {
          max: 100,
          padding: 0,
          label: {
            text: "Efficiency (%)",
            position: "outer-top"
          }
        }
      },
      onresized: function() {
        c3SimpleService["#summary-chart"].resize({
          height: $window.innerHeight * 0.4 - 30 //Height is 40vh - 30px
        });
      }
    };

    //For setting active sample on table row mouseenter
    $scope.setActiveSample = function(sample, index) {
      $scope.activeSample = sample.id;

      //Show vertical grid line on the chart
      if (c3SimpleService["#summary-chart"] != undefined) {
        c3SimpleService["#summary-chart"].xgrids([{ value: index }]);
      }
    };

    ///For unsetting sample on table row mouseleave
    $scope.unsetActiveSample = function() {
      $scope.activeSample = "";

      //Hide vertical grid line on the chart
      if (c3SimpleService["#summary-chart"] != undefined) {
        c3SimpleService["#summary-chart"].xgrids([]);
      }
    };

    //When the table is sorted set the sortorder and update the chart
    $scope.$on("tablesort:sortOrder", function(event, sortOrder) {
      $scope.sortOrder = sortOrder.map(function(o) {
        return (o.order ? "-" : "") + o.name;
      });

      updateChart();
    });

    //Check periodically for new data
    var startStatusCheck = function(delay) {
      if (!angular.isDefined($scope.checkStatus)) {
        $scope.checkStatus = $interval(function() {
          getData($stateParams.run_id);
        }, delay);
      }
    };

    //Kill the status check interval
    var killStatusCheck = function() {
      console.log("Kill status check");
      if (angular.isDefined($scope.checkStatus)) {
        $interval.cancel($scope.checkStatus);
        $scope.checkStatus = undefined;
      }

      $scope.status.loading = false;
    };

    // default orderBy compare function doesn't compare nulls to numbers correctly
    var compareWithNulls = function(val1, val2) {
      if (val1.value == "null") {
        return -1;
      } else if (val2.value == "null") {
        return 1;
      }
      return val1.value < val2.value ? -1 : 1;
    };

    //Update the chart dynamically
    var updateChart = function() {
      console.log("Updating chart...");

      if ($scope.sortOrder != undefined) {
        $scope.successfulResults = $filter("orderBy")(
          $scope.successfulResults,
          $scope.sortOrder[0],
          false,
          compareWithNulls
        );
      }

      if (c3SimpleService["#summary-chart"] != undefined) {
        var keyVals = ["ice", "ko_score"];
        if ($scope.containsKnockin) {
          keyVals.push("ki_score");
        }

        c3SimpleService["#summary-chart"].load({
          json: $scope.successfulResults,
          keys: {
            value: keyVals
          },
          done: function() {
            console.log("Data loaded", $scope.successfulResults);
          }
        });
      } else {
        console.log("Chart undefined");
        $scope.summaryChart.data.json = $scope.successfulResults;
      }
    };

    //Get and sort/filter analysis data
    var getData = function(runID) {
      console.log("Checking for new data...");

      analyze.clearCache();

      analyze
        .getAnalysis(runID)
        .then(function(response) {
          console.log("Analysis for run: ", response.data.id, response);
          Flash.clear();

          //If the response fails, go to error page
          if (response.data.status == "failed" && !$scope.ignoreAnalyzeError) {
            console.log("Get Analysis Error ==>", response);
            $state.go("error", {
              message: "Problem fetching analysis.",
              details: {
                response: response,
                run_id: runID
              },
              backTo: "analyze"
            });
            return;
          }

          $scope.ice = response.data;

          //If there is only one experiment, skip the summary page
          if (
            $scope.ice.status == "completed" &&
            $scope.ice.total_experiments == 1
          ) {
            $state.go("analyze.results.detail", {
              sample_label: $scope.ice.results[0].label
            });
          }

          //If we're showing the chart filter results and update the chart
          if (
            $scope.ice.status == "completed" ||
            $scope.debugOptions.iceloader == "none" ||
            $scope.debugOptions.iceloader == "combo"
          ) {
            $scope.successfulResults = $filter("filter")($scope.ice.results, {
              status: "succeeded"
            });
            $scope.failedResults = $filter("filter")($scope.ice.results, {
              status: "!succeeded"
            });

            $scope.containsKnockin =
              $scope.ice.results.filter(function(result) {
                return result.ki_score;
              }).length > 0;

            $timeout(function() {
              updateChart();
            });

            //If there is no more data to load or no email status to check for we can kill the interval
            if (
              $scope.ice.status == "completed" &&
              ($scope.downloadEmail.status == "sent" ||
                $scope.downloadEmail.status == undefined)
            ) {
              // We can stop checking
              killStatusCheck();
            }
          }

          //Grab the download id stored in localstorage based on the run id
          var downloadId = $scope.analyzeOptions.runDownloads[$scope.ice.id];

          if (downloadId != undefined) {
            //If there is a download id, update the download status
            analyze
              .getAnalysisDownloadStatus(downloadId)
              .then(function(response) {
                console.log("Download status: ", response);
                $scope.downloadEmail = response.data;
              })
              .catch(function(error) {
                Flash.clear();
                Flash.create("danger", "Problem fetching download status.");
              });
          }
        })
        .catch(function(error) {
          //If there is an error stop the interval
          killStatusCheck();

          console.log("ERROR", error);
          Flash.clear();

          if (error.status == 404) {
            $state.go("error", {
              message: "Couldn't find a run with id " + $stateParams.run_id,
              details: {
                response: error,
                run_id: $stateParams.run_id
              },
              backTo: "analyze"
            });
          } else {
            $state.go("error", {
              message: "Problem loading analysis results.",
              details: {
                response: error,
                run_id: $stateParams.run_id
              },
              backTo: "analyze"
            });
          }
        });
    };

    //On page load results from URL params
    if ($stateParams.run_id != undefined || $stateParams.run_id != "") {
      getData($stateParams.run_id);
      startStatusCheck(1000);
    } else {
      $state.go("analyze");
    }

    //Kill interval if user navigates away
    $transitions.onSuccess({ to: "analyze", from: "analyze.results" }, function(
      transition
    ) {
      killStatusCheck();
    });

    //Get data on transition to page (mostly for the recents debug menu)
    $transitions.onSuccess({ to: "analyze.results", from: "**" }, function(
      transition
    ) {
      getData(transition.params().run_id);
    });

    //Watch for new results and update the chart if they've changed
    $scope.$watch("successfulResults.length", function(newVal, oldVal) {
      if (!angular.equals(newVal, oldVal)) {
        $timeout(function() {
          updateChart();
        }, 150);
      }
    });

    //If the user initiates an download request after the status checker interval has been killed, we need to restart it
    $scope.$watch("downloadEmail.status", function(newVal, oldVal) {
      if (newVal == "requested") {
        if (!angular.isDefined($scope.checkStatus)) {
          startStatusCheck(3000); //We can check less frequently since there is no chart to update
        }
      } else if (newVal == "sent" && $scope.ice.status == "completed") {
        //Once the download request has been finished kill the status check
        killStatusCheck();
      }
    });
  }
]);

angular.module('app')
  .controller('error', ['$scope', '$stateParams', function ($scope, $stateParams) {

    $scope.errorMessage = $stateParams.message;
    $scope.details = $stateParams.details;
    $scope.backTo = $stateParams.backTo;
    
  }]);

angular.module('app')
  .controller('modals', ['$scope', '$uibModal', function ($scope, $uibModal) {
    $scope.openModal = function (templateUrl, size) {

      $uibModal.open({
        templateUrl: templateUrl,
        size: size,
        scope:$scope,
      }).result.then(function(){}, function(res){});
      
    }
  }]);
